const dacia = {
  StepOne: [
    {
      name: 'SectionFilters',
      translations: {
        dealerLocator: {
          headline: 'Wybierz lokalizację',
          headlinePrimary: 'i dealera',
          labelSearchByPlace: 'Wpisz miasto, ulicę lub kod pocztowy',
          labelGeolocalization: 'MOJA LOKALIZACJA',
          placeholderGeolocalization: 'GEOLOKALIZACJA',
          loadingText: 'Ładowanie lokalizacji..'
        },
        carousel: {
          headline: 'Wybierz',
          headlinePrimary: 'model',
          engines: {
            hev: 'hybrid',
            phev: 'hybrid plug-in',
            electric: 'electric'
          }
        },
        tabs: {
          headline: 'Wybierz model',
          passengerModels: 'osobowe',
          commercialModels: 'dostawcze',
          labelPassenger: 'Samochody osobowe',
          labelCommercial: 'Samochody dostawcze'
        },
        checkbox: {
          mainText: 'Pokaż tylko samochody hybrydowe i elektryczne',
          noElectricsFound:
            'Brak dostępnych samochodów hybrydowych i elektrycznych dla wybranych parametrów.',
          engines: ['hybrid plug-in', 'electric']
        }
      }
    },
    {
      name: 'SectionCarList',
      translations: {
        labelRowsPerPage: 'Wyników na stronie',
        labelVehiclesCount: 'Ilość aut spełniających dane kryteria:',
        engines: {
          hev: 'hybrid',
          phev: 'hybrid plug-in',
          electric: 'electric'
        },
        carListReservation: {
          buttonCTA: 'Sprawdź dostępne terminy',
          headline: 'Wybierz datę i godzinę',
          headlineModel: 'Jazda próbna:',
          noFreeHoursText: ' Brak<br />wolnych<br />terminów',
          noAvailableDatesText:
            'Brak wolnych terminów<br /> Spróbuj znaleźć inny samochód<br /> lub spróbować później',
          buttonConfirm: 'Dalej'
        }
      }
    },
    {
      name: 'SectionFaq',
      translations: {
        expandBox: {
          desc: 'Sprawdź jak to działa i co warto wiedzieć?',
          button: 'zwiń'
        },
        collapseBox: {
          desc: 'Jak to działa i co warto wiedzieć?',
          button: 'zobacz'
        },
        steps: {
          title: 'Jak to działa?',
          subtitle: 'Zapraszamy do wybranego salonu w dniu jazdy!',
          stepsData: [
            {
              icon: 'AutoDealer',
              title: 'Wybierz auto<br /> i dealera',
              viewBox: '0 0 101 35'
            },
            {
              icon: 'ChooseHour',
              title: 'Wybierz datę i godzine',
              viewBox: '0 0 43 43'
            },
            {
              icon: 'YourData',
              title: 'Podaj swoje dane …',
              viewBox: '0 0 56 38'
            },
            {
              icon: 'AutoSummary',
              title: 'sprawdź podsumowanie',
              viewBox: '0 0 39 47'
            },
            {
              icon: 'AutoSms',
              title: 'wprowadź kod sms',
              viewBox: '0 0 25 43'
            },
            {
              icon: 'AutoFinish',
              title: '… i już, auto zarezerwowane. ',
              viewBox: '0 0 46 38'
            }
          ]
        },
        accordionTitle: 'Najczęściej zadawane pytania',
        accordionData: [
          {
            title: 'Czy mogę przejechać się więcej niż jednym modelem?',
            desc: 'Oczywiście. Każde auto dostępne do jazd próbnych jest do Państwa dyspozycji.'
          },
          {
            title: 'Czy będę mógł prowadzić?',
            desc: 'Tak. Dodatkowo pasażerem będzie sprzedawca, który opowie o samochodzie, jego możliwościach i jak najlepiej z niego korzystać.'
          },
          {
            title:
              'Czy będziemy mogli podjechać do mnie do domu, sprawdzić czy bezproblemowo wjadę do garażu?',
            desc: 'Jest taka możliwość. Należy wcześniej poinformować sprzedawcę.'
          },
          {
            title: 'Czy jazda próbna jest płatna? Czy będę musiał zatankować samochód na koniec?',
            desc: 'Jazda jest całkowicie bezpłatna.'
          },
          {
            title: 'Czy mogę zabrać ze sobą osobę towarzyszącą?',
            desc: 'Oczywiście.'
          }
        ]
      }
    }
  ],
  StepTwo: {
    headline: 'Jazda próbna: ',
    subheadline: 'Wybierz termin',
    calendarDetails: {
      dateHeader: 'Data',
      hourHeader: 'Godzina',
      buttonCTA: 'Wybierz inny termin',
      reservedTerm: 'Wybrany termin został już zarezerwowany, prosimy o ponowne wybranie terminu',
      noFreeHoursText: ' Brak<br />wolnych<br />terminów',
      headlineModel: 'Jazda próbna:',
      buttonConfirm: 'Potwierdź',
      noAvailableDatesText:
        'Brak wolnych terminów<br /> Spróbuj znaleźć inny samochód<br /> lub spróbować później',
      nextButton: 'dalej',
      prevButton: 'wróć'
    }
  },
  StepThree: {
    headline: 'Jazda próbna: ',
    subheadline: 'Data i godzina jazdy próbnej',
    formHeadline: 'Podaj swoje dane',
    nextButton: 'dalej',
    prevButton: 'wróć',
    marketingDetails: {
      marketingGDPR:
        'Współadministratorami Pani/Pana danych osobowych są: Renault Polska spółka z ograniczoną odpowiedzialnością z siedzibą w Warszawie (02-674) przy ul. Marynarskiej 13 oraz wybrany przez Panią/Pana dealer, którego pełną nazwę i adres wskazano w niniejszym formularzu powyżej. Pani/Pana dane będą wykorzystywane w celu zarezerwowania i odbycia jazdy próbnej, a także do celu marketingu Współadministratorów, w tym mogą podlegać profilowaniu (celem dopasowania ofert handlowych oraz wskazania najbliższego salonu sprzedaży). Przysługuje Pani/Panu prawo do żądania dostępu do danych, ich sprostowania, usunięcia, ograniczenia przetwarzania, prawo do wyrażenia sprzeciwu i wycofania zgody, prawo do przenoszenia danych, a także wniesienia skargi do Prezesa UODO. Kontakt w sprawach dot. ochrony danych: <strong><a href="mailto:renault.iod@renault.com.pl" target="_blank">renault.iod@renault.com.pl</a></strong>. <strong><a href="https://www.renault.pl/przetwarzanie-danych-osobowych/jazda-probna-zgoda.html">Pełna informacja o przetwarzaniu Pani/Pana danych osobowych</a></strong>',
      marketingMethods:
        'Wyrażam zgodę na dostarczanie informacji marketingowych, w tym handlowych o produktach i usługach Renault za pośrednictwem wybranych przeze mnie kanałów komunikacji:',
      marketingRequire: '* pola obowiązkowe'
    }
  },
  StepFour: {
    headline: 'Potwierdź wybrane auto i termin jazdy próbnej',
    nextButton: 'dalej',
    prevButton: 'wróć',
    summaryBoxes: {
      carInfo: 'Samochód:',
      reservationInfo: 'Termin:',
      dealerInfo: 'Adres dealera:',
      clientInfo: 'Klient:',
      emailInfo: 'email:',
      phoneInfo: 'tel.:'
    },
    engines: {
      hev: 'hybrid',
      phev: 'hybrid plug-in',
      electric: 'electric'
    },
    section360view: {
      view360Label: 'Widok 360°',
      switchLabel: 'Obróc ekran dla lepszego doświadczenia'
    }
  },
  horizontalStepper: {
    steps: [
      'Wybierz auto i dealera',
      'Wybierz datę i godzinę',
      'Podaj swoje dane',
      'Sprawdź podsumowanie',
      'Wpisz kod SMS... i już, jazda umówiona'
    ]
  },
  StepFive: {
    smsCodeVerification: {
      headline: 'Twój kod SMS',
      text: 'Wysłaliśmy Ci wiadomość SMS z kodem. Wpisz przesłany kod, by dokończyć weryfikację',
      buttonCTA: 'Wyślij',
      buttonSendAgain: 'Wysłać SMS ponownie?',
      nextButton: 'dalej'
    }
  },
  ThanksPage: {
    headline: 'Twoja jazda próbna Dacia',
    headlinePrimary: 'została zarezerwowana',
    subheadline: 'Dziękujemy!',
    infoBox:
      'Czas trwania jazdy próbnej to ok. 30 minut.<br> Prosimy o punktualność. Przy spóźnieniu powyżej 15 minut jazda próbna może zostać anulowana. <br> Pamiętaj, aby mieć przy sobie ważne prawo jazdy.',
    summaryBoxes: {
      carInfo: 'Samochód:',
      reservationInfo: 'Termin:',
      dealerInfo: 'Adres dealera:',
      clientInfo: 'Klient:',
      emailInfo: 'email:',
      phoneInfo: 'tel.:'
    },
    engines: {
      hev: 'hybrid',
      phev: 'hybrid plug-in',
      electric: 'electric'
    },
    goBackButton: 'powrót do strony głównej',
    actionButtons: [
      { text: 'pobierz w pdf', type: 'pdfDownload' },
      { text: 'dodaj do kalendarza google/outlook', type: 'saveToCalendar' },
      { text: 'dowiedz się więcej o modelu', type: 'goToModelDetails' }
    ]
  },
  notFoundData: {
    text: 'Ups! Strona, której szukasz, nie istnieje.',
    buttonCTA: 'WRÓC DO STRONY GŁÓWNEJ'
  }
};

const renault = {
  StepOne: [
    {
      name: 'SectionFilters',
      translations: {
        dealerLocator: {
          headline: 'Wybierz lokalizację i dealera',
          labelSearchByPlace: 'Wpisz miasto, ulicę lub kod pocztowy',
          labelGeolocalization: 'MOJA LOKALIZACJA',
          placeholderGeolocalization: 'GEOLOKALIZACJA',
          loadingText: 'Ładowanie lokalizacji..'
        },
        carousel: {
          engines: {
            hev: 'hybrid',
            phev: 'plug-in hybrid',
            electric: 'electric'
          }
        },
        tabs: {
          headline: 'Wybierz model',
          passengerModels: 'osobowe',
          commercialModels: 'dostawcze',
          labelPassenger: 'Samochody osobowe',
          labelCommercial: 'Samochody dostawcze'
        },
        checkbox: {
          mainText: 'Pokaż tylko samochody hybrydowe i elektryczne',
          noElectricsFound:
            'Brak dostępnych samochodów hybrydowych i elektrycznych dla wybranych parametrów.',
          engines: ['full hybrid', 'electric']
        }
      }
    },
    {
      name: 'SectionCarList',
      translations: {
        labelRowsPerPage: 'Wyników na stronie',
        labelVehiclesCount: 'Ilość aut spełniających dane kryteria:',
        engines: {
          hev: 'hybrid',
          phev: 'plug-in hybrid',
          electric: 'electric'
        },
        carListReservation: {
          buttonCTA: 'Zaproponuj termin jazdy',
          buttonCTASimple: 'Ustal termin jazdy później',
          headline: 'Wybierz datę i godzinę',
          headlineModel: 'Jazda próbna:',
          noFreeHoursText: ' Brak<br />wolnych<br />terminów',
          noAvailableDatesText:
            'Brak wolnych terminów<br /> Spróbuj znaleźć inny samochód<br /> lub spróbować później',
          buttonConfirm: 'Dalej'
        },
        stepperLong: [
          'Wybierz auto i dealera',
          'Zaproponuj datę i godzinę',
          'Podaj swoje dane',
          'Sprawdź podsumowanie',
          'Potwierdź kodem SMS'
        ],
        stepperShort: ['Wybierz auto i dealera', 'Podaj swoje dane', 'Podsumowanie']
      }
    },
    {
      name: 'SectionFaq',
      translations: {
        expandBox: {
          desc: 'Sprawdź jak to działa i co warto wiedzieć?',
          button: 'zwiń'
        },
        collapseBox: {
          desc: 'Jak to działa i co warto wiedzieć?',
          button: 'zobacz'
        },
        steps: {
          title: 'Jak to działa?',
          subtitle: 'Zapraszamy do wybranego salonu w dniu jazdy!',
          stepsData: [
            {
              icon: 'AutoDealer',
              title: 'Wybierz auto<br /> i dealera',
              viewBox: '0 0 101 35'
            },
            {
              icon: 'ChooseHour',
              title: 'Wybierz datę i godzine',
              viewBox: '0 0 43 43'
            },
            {
              icon: 'YourData',
              title: 'Podaj swoje dane …',
              viewBox: '0 0 56 38'
            },
            {
              icon: 'AutoSummary',
              title: 'sprawdź podsumowanie',
              viewBox: '0 0 39 47'
            },
            {
              icon: 'AutoSms',
              title: 'wprowadź kod sms',
              viewBox: '0 0 25 43'
            },
            {
              icon: 'AutoFinish',
              title: '… i już, auto zarezerwowane. ',
              viewBox: '0 0 46 38'
            }
          ]
        },
        accordionTitle: 'Najczęściej zadawane pytania',
        accordionData: [
          {
            title: 'Czy mogę przejechać się więcej niż jednym modelem?',
            desc: 'Oczywiście. Każde auto dostępne do jazd próbnych jest do Państwa dyspozycji.'
          },
          {
            title: 'Czy będę mógł prowadzić?',
            desc: 'Tak. Dodatkowo pasażerem będzie sprzedawca, który opowie o samochodzie, jego możliwościach i jak najlepiej z niego korzystać.'
          },
          {
            title:
              'Czy będziemy mogli podjechać do mnie do domu, sprawdzić czy bezproblemowo wjadę do garażu?',
            desc: 'Jest taka możliwość. Należy wcześniej poinformować sprzedawcę.'
          },
          {
            title: 'Czy jazda próbna jest płatna? Czy będę musiał zatankować samochód na koniec?',
            desc: 'Jazda jest całkowicie bezpłatna.'
          },
          {
            title: 'Czy mogę zabrać ze sobą osobę towarzyszącą?',
            desc: 'Oczywiście.'
          }
        ]
      }
    }
  ],
  StepTwo: {
    headline: 'Jazda próbna: ',
    subheadline: 'Zaproponuj termin',
    calendarDetails: {
      dateHeader: 'DATA',
      hourHeader: 'GODZINA',
      buttonCTA: 'Zaproponuj inny termin',
      reservedTerm: 'Wybrany termin został już zarezerwowany, prosimy o ponowne wybranie terminu',
      noFreeHoursText: ' Brak<br />wolnych<br />terminów',
      headlineModel: 'Jazda próbna:',
      buttonConfirm: 'Potwierdź',
      noAvailableDatesText:
        'Brak wolnych terminów<br /> Spróbuj znaleźć inny samochód<br /> lub spróbować później',
      noSelectedDate: 'Wybierz datę<br /> z kalendarza',
      nextButton: 'dalej',
      prevButton: 'wróć'
    }
  },
  StepThree: {
    headline: 'Jazda próbna: ',
    subheadline: 'Data i godzina jazdy próbnej',
    formHeadline: 'Podaj swoje dane',
    nextButton: 'dalej',
    prevButton: 'wróć',
    marketingDetails: {
      marketingGDPR:
        'Współadministratorami Pani/Pana danych osobowych są: Renault Polska spółka z ograniczoną odpowiedzialnością z siedzibą w Warszawie (02-674) przy ul. Marynarskiej 13 oraz wybrany przez Panią/Pana dealer, którego pełną nazwę i adres wskazano w niniejszym formularzu powyżej. Pani/Pana dane będą wykorzystywane w celu zarezerwowania i odbycia jazdy próbnej, a także do celu marketingu Współadministratorów, w tym mogą podlegać profilowaniu (celem dopasowania ofert handlowych oraz wskazania najbliższego salonu sprzedaży). Przysługuje Pani/Panu prawo do żądania dostępu do danych, ich sprostowania, usunięcia, ograniczenia przetwarzania, prawo do wyrażenia sprzeciwu i wycofania zgody, prawo do przenoszenia danych, a także wniesienia skargi do Prezesa UODO. Kontakt w sprawach dot. ochrony danych: <strong><a href="mailto:renault.iod@renault.com.pl" target="_blank">renault.iod@renault.com.pl</a></strong>. <strong><a href="https://www.renault.pl/przetwarzanie-danych-osobowych/jazda-probna-zgoda.html">Pełna informacja o przetwarzaniu Pani/Pana danych osobowych</a></strong>',
      marketingMethods:
        'Wyrażam zgodę na dostarczanie informacji marketingowych, w tym handlowych o produktach i usługach Renault za pośrednictwem wybranych przeze mnie kanałów komunikacji:',
      marketingRequire: '* pola obowiązkowe'
    },
    stepperShort: ['Wybierz auto i dealera', 'Podaj swoje dane', 'Podsumowanie']
  },
  StepFour: {
    headline: 'Podsumowanie',
    nextButton: 'dalej',
    prevButton: 'wróć',
    summaryBoxes: {
      carInfo: 'Samochód:',
      reservationInfo: 'Proponowany termin:',
      reservationDisclaimer: 'Termin zostanie potwierdzony przez dealera',
      dealerInfo: 'Adres dealera:',
      clientInfo: 'Klient:',
      emailInfo: 'email:',
      phoneInfo: 'tel.:'
    },
    engines: {
      hev: 'hybrid',
      phev: 'plug-in hybrid',
      electric: 'electric'
    },
    section360view: {
      view360Label: 'Widok 360°',
      switchLabel: 'Obróc ekran dla lepszego doświadczenia'
    }
  },
  StepFive: {
    smsCodeVerification: {
      headline: 'Wpisz kod SMS',
      text: 'Wysłaliśmy Ci wiadomość SMS z kodem. Wpisz przesłany kod, by dokończyć weryfikację',
      buttonCTA: 'Wyślij',
      buttonSendAgain: 'Wysłać SMS ponownie?',
      nextButton: 'dalej'
    }
  },
  ThanksPage: {
    headline: 'Twoja jazda próbna Renault',
    headlinePrimary: 'została zarezerwowana',
    subheadline: 'Dziękujemy!',
    infoBox:
      'Czas trwania jazdy próbnej to ok. 30 minut.<br> Prosimy o punktualność. Przy spóźnieniu powyżej 15 minut jazda próbna może zostać anulowana. <br> Pamiętaj, aby mieć przy sobie ważne prawo jazdy.',
    summaryBoxes: {
      carInfo: 'Samochód:',
      reservationInfo: 'Proponowany termin:',
      reservationDisclaimer: 'Termin zostanie zaproponowany przez dealera',
      dealerInfo: 'Adres dealera:',
      clientInfo: 'Klient:',
      emailInfo: 'email:',
      phoneInfo: 'tel.:'
    },
    engines: {
      hev: 'hybrid',
      phev: 'plug-in hybrid',
      electric: 'electric'
    },
    goBackButton: 'powrót do strony głównej',
    actionButtons: [
      { text: 'pobierz w pdf', type: 'pdfDownload' },
      { text: 'dodaj do kalendarza google/outlook', type: 'saveToCalendar' },
      { text: 'dowiedz się więcej o modelu', type: 'goToModelDetails' }
    ]
  },
  notFoundData: {
    text: 'Ups! Strona, której szukasz, nie istnieje.',
    buttonCTA: 'WRÓC DO STRONY GŁÓWNEJ'
  }
};

export const translations = { dacia, renault };
