window.dataLayer = window.dataLayer || [];

// Odsłona - strona główna
export const pageView = () => {
  window.dataLayer.push({
    brand: process.env.REACT_APP_BRAND,
    countryCode: 'PL',
    languageCode: 'pl',
    businessType: 'new-car',
    pageName: 'homepage',
    pageType: 'homepage'
  });
};

// Akcja - strona główna - kliknięcie na przycisk
export const stepperOpen = ({ dealerName, dealerId, vehicleModel, vehicleId, formType }) => {
  window.dataLayer.push({
    brand: process.env.REACT_APP_BRAND,
    countryCode: 'PL',
    languageCode: 'pl',
    formType,
    formCategory: 'lead_newcar',
    formStepName: 'start',
    formStepNumber: 'step-0',
    event: 'formOpen',
    pageType: 'form',
    businessType: 'new-car',
    dealerName: dealerName ?? '',
    dealerId: dealerId ?? '',
    vehicleModel: vehicleModel ?? '',
    vehicleId: vehicleId ?? '',
    formName: 'ML-BATD-ONLINE'
  });
};

export const singleStepView = ({
  pagePath,
  pageTitle,
  formType,
  dealerName,
  dealerId,
  vehicleModel,
  vehicleId,
  campaign
}) => {
  window.dataLayer.push({
    brand: process.env.REACT_APP_BRAND,
    countryCode: 'PL',
    languageCode: 'pl',
    formType,
    formCategory: 'lead_newcar',
    pagePath, // zmienna
    pageName: pageTitle, // zmienna
    event: 'virtualPageview',

    pageType: 'form',
    businessType: 'new-car',
    dealerName: dealerName ?? '',
    dealerId: dealerId ?? '',
    vehicleModel: vehicleModel ?? '',
    vehicleId: vehicleId ?? '',
    campaign: campaign ?? '',
    formName: 'ML-BATD-ONLINE'
  });
};

export const singleStepAction = ({
  formStepName,
  formStepNumber,
                                   formType,
  event,
  campaign,
  dealerName,
  dealerId,
  vehicleModel,
  vehicleId
}) => {
  window.dataLayer.push({
    brand: process.env.REACT_APP_BRAND,
    countryCode: 'PL',
    languageCode: 'pl',
    formType,
    formCategory: 'lead_newcar',
    formStepName, // zmienna
    formStepNumber, // zmienna
    event,

    pageType: 'form',
    businessType: 'new-car',
    dealerName: dealerName ?? '',
    dealerId: dealerId ?? '',
    vehicleModel: vehicleModel ?? '',
    vehicleId: vehicleId ?? '',
    campaign: campaign ?? '',
    formName: 'ML-BATD-ONLINE'
  });
};

export const downloadEvent = ({
  formType,
  leadId,
  brochureName,
  dealerId,
  vehicleId,
  vehicleModel,
  dealerName
}) => {
  window.dataLayer.push({
    brand: process.env.REACT_APP_BRAND,
    countryCode: 'PL',
    languageCode: 'pl',
    formType,
    formCategory: 'lead_newcar',
    leadId,
    event: 'download',
    brochureName: brochureName ?? '',

    pageType: 'form',
    businessType: 'new-car',
    dealerName: dealerName ?? '',
    dealerId: dealerId ?? '',
    vehicleModel: vehicleModel ?? '',
    vehicleId: vehicleId ?? '',
    formName: 'ML-BATD-ONLINE'
  });
};
