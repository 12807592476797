/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Container } from '@material-ui/core';
import { useEffect, useState, lazy, Suspense, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useStyles } from './sectionFilters.jss';

const SectionDealerLocator = lazy(() => import('./dealer-locator/sectionDealerLocator'));
const SectionDealersList = lazy(() => import('./dealers-list/sectionDealersList'));
const SectionEngines = lazy(() => import('./engines/sectionEngines'));
const SectionFiltersTabs = lazy(() => import('./tabs/sectionTabs'));
const SectionFiltersCarousel = lazy(() => import('./carousel/sectionCarousel'));

const SectionFilters = ({
  actions: {
    saveVehicleModel,
    saveVehicleType,
    saveSelectedDealers,
    fetchVehicles,
    fetchDictionaries,
    saveElectricChecked,
    fetchDictionariesByModel,
    fetchDictionariesByDealers
  },
  translations: { carousel, dealerLocator, tabs, checkbox },
  match
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    vehicleType,
    vehicleModel,
    models,
    dealers,
    selectedDealers,
    hasAnyElectric,
    otherTypeAvailable
  } = useSelector((state) => state.carBooking);
  const [loadingModels, setLoadingModels] = useState(true);
  const [loadingDealers, setLoadingDealers] = useState(false);
  const [place, setPlace] = useState(null);
  const [electricChecked, setElectricChecked] = useState(null);
  const [electricExist, setElectricExist] = useState(true);
  const [dealersForLocation, setDealersForLocation] = useState([]);
  const [initialCheckDone, setInitialCheckDone] = useState(false);
  const inputRef = useRef(null);
  const getDictionariesByModel = async (model) => {
    setLoadingDealers(true);
    await dispatch(
      fetchDictionariesByModel({
        model,
        onlyElectric,
        place
      })
    );
    setLoadingDealers(false);
  };

  const getDictionariesByDealers = async (bir) => {
    setLoadingModels(true);
    await dispatch(
      fetchDictionariesByDealers({
        bir,
        onlyElectric,
        place
      })
    );
    setLoadingModels(false);
  };

  const onlyElectric = electricExist ? electricChecked : electricExist;

  const getVehicles = async ({ model = vehicleModel, bir = selectedDealers, onlyElectric }) => {
    await dispatch(
      fetchVehicles({
        bir,
        vehicleModel: model,
        onlyElectric,
        place,
        pageIndex: 0
      })
    );
  };

  const handleModelChange = async (vehicleModel, engineType, birs) => {
    if (place) {
      const availableDealers = selectedDealers.filter((item) => birs.includes(item));
      await dispatch(saveSelectedDealers(availableDealers));
      await dispatch(saveVehicleModel(vehicleModel));
      await getDictionariesByModel(vehicleModel);
      getVehicles({ bir: availableDealers, model: vehicleModel });
    }
    if (!place) {
      setElectricExist(['HEV', 'ELECTRIC', 'PHEV'].includes(engineType));
      getVehicles({ model: vehicleModel });
      await dispatch(saveVehicleModel(vehicleModel));
    }
    setTimeout(
      () => {
        document.getElementById('SectionDealerLocator')?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'start'
        });
        setTimeout(() => {
          inputRef.current?.querySelector('input')?.focus();
        }, 300); // delay to allow smooth scroll before focus
      },

      100
    );

    setTimeout(() => {
      const svg = inputRef.current?.querySelector('svg');
      svg.style.transition = 'transform 0.3s ease';
      svg.style.transform = 'scale(1.5)';
    }, 800);

    setTimeout(() => {
      const svg = inputRef.current?.querySelector('svg');
      svg.style.transform = 'scale(1)';
    }, 1600);
  };

  const handleTypeChange = async (vehicleType) => {
    setLoadingModels(true);
    setElectricChecked(null);
    setPlace(null);
    await dispatch(saveElectricChecked(null));
    await dispatch(saveVehicleType(vehicleType));
    await dispatch(saveVehicleModel(null));
    await dispatch(saveSelectedDealers([]));
    await getVehicles({
      vehicleType,
      model: null,
      bir: [],
      onlyElectric: false
    });
    await dispatch(fetchDictionaries(vehicleType));
    setLoadingModels(false);
  };

  const clearModelsList = () => {
    dispatch(saveVehicleModel(null));
    getDictionariesByModel(null);
    getVehicles({ model: null });
    if (!place) setElectricExist(hasAnyElectric);
  };

  useEffect(async () => {
    if (electricChecked == null) return;
    const availableDealers = selectedDealers?.filter((item) =>
      dealers.some(({ bir, hasAnyElectric }) => bir === item && hasAnyElectric)
    );
    await dispatch(saveSelectedDealers(availableDealers));
    await getDictionariesByModel(vehicleModel);
    await getDictionariesByDealers(availableDealers);
    await getVehicles({ bir: availableDealers });
  }, [electricChecked]);

  useEffect(async () => {
    if (place == null) return;
    await dispatch(saveSelectedDealers([]));
    await getDictionariesByModel(vehicleModel);
    await getDictionariesByDealers();
    await getVehicles({ bir: [] });
  }, [place]);

  useEffect(() => {
    setElectricExist(hasAnyElectric);
  }, [hasAnyElectric]);

  useEffect(() => {
    let onModelsLoad = true;
    if (onModelsLoad && models.length) setLoadingModels(false);
    if (match.params.model && models.length && !initialCheckDone) {
      const currentModel = models.find(({ slug }) => slug === match.params.model);
      setElectricExist(['HEV', 'ELECTRIC', 'PHEV'].includes(currentModel?.engineType));
      setInitialCheckDone(true);
    }
    return () => {
      onModelsLoad = false;
    };
  }, [models, match.params.model]);

  const onDealerSearch = async (bir) => {
    await dispatch(saveSelectedDealers(bir));
    await getDictionariesByDealers(bir);
    await getVehicles({ bir });
  };

  const onPlaceSelected = (place) => {
    if (!place) setPlace('');
    else
      setPlace({
        latitude: place.latitude,
        longitude: place.longitude
      });
  };

  useEffect(() => {
    if (place && !vehicleModel) setDealersForLocation(dealers);
  }, [dealers]);

  const setDealers = () => {
    if (place && !vehicleModel) return dealersForLocation;
    else return dealers;
  };

  return (
    <>
      {process.env.REACT_APP_BRAND === 'renault' && otherTypeAvailable && (
        <Suspense fallback={<div style={{ height: '106px' }}></div>}>
          <SectionFiltersTabs
            translations={tabs}
            vehicleType={vehicleType}
            handleTypeChange={handleTypeChange}
            loading={loadingModels}
          />
        </Suspense>
      )}
      <Suspense fallback={<div style={{ height: '352px', padding: '32px 0' }}></div>}>
        <SectionFiltersCarousel
          translations={carousel}
          vehicleModel={vehicleModel}
          handleModelChange={handleModelChange}
          clearModelsList={clearModelsList}
          loading={loadingModels}
          models={models}
        />
      </Suspense>
      <Container id="SectionDealerLocator" className={classes.container} disableGutters>
        {process.env.REACT_APP_BRAND === 'renault' && (
          <Suspense fallback={<></>}>
            <SectionEngines
              translations={checkbox}
              onEngineTypeChange={(onlyElectric) => {
                dispatch(saveElectricChecked(onlyElectric));
                setElectricChecked(onlyElectric);
              }}
              isElectricExist={electricExist}
              hasAnyElectric={hasAnyElectric}
              saveElectricChecked={saveElectricChecked}
            />
          </Suspense>
        )}
        <Suspense fallback={<></>}>
          <SectionDealerLocator
            ref={inputRef}
            translations={dealerLocator}
            onPlaceSelected={onPlaceSelected}
            place={place}
          />
        </Suspense>
        <Box display="flex" my={4}>
          {place && (
            <Suspense fallback={<></>}>
              <SectionDealersList
                onDealerSearch={onDealerSearch}
                place={place}
                loading={loadingDealers}
                vehicleModel={vehicleModel}
                dealers={setDealers()}
                selectedDealers={selectedDealers}
              />
            </Suspense>
          )}
        </Box>
      </Container>
    </>
  );
};

export default SectionFilters;
